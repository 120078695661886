import React, { Component } from "react";

import masterIcon from "../../../assets/imgs/profile/mastercard.svg";
import visaIcon from "../../../assets/imgs/profile/visa.svg";
import UiBtnGreen from "../buttons/UiBtnGreen";

class UiModalPay extends Component {
  state = {
    activePay: 0,
    selectBonus: 0,
    promoCode: "",
  };
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {}

  _changeBonus(_v) {
    const re = /^[0-9\b]+$/;
    if (_v === "" || re.test(_v)) {
      if (_v <= this.props.bonus && this.props.price * 0.2 >= _v)
        this.setState({ selectBonus: Math.round(_v) });
    }
  }

  render() {
    return (
      <div className={"modal-wrap " + (this.props.show ? "show" : "hide")}>
        <div className="modal-payment">
          <div className="card-title">
            <h3> </h3>
            <button
              className="card-title-btn"
              onClick={() => this.props.callBackClose()}
            >
              <img src={require("../../../assets/imgs/ui/close.svg")} />
            </button>
          </div>
          <div className="payment-wrap">
            <div className="program-info">
              <img
                src={require("../../../assets/imgs/menu-type/healthy.svg")}
              />
              <div className="program-info-title">
                <h3>{this.props.title}</h3>
                <p>{this.props.description}</p>

                {this.props.days ? (
                  <p>
                    <strong>
                      {global.locale.modal_pay_on} {this.props.days}{" "}
                      {global.locale.modal_pay_days}
                    </strong>
                  </p>
                ) : null}
              </div>
              <div className="program-info-price">
                <p>
                  {this.props.price} {global.locale.modal_pay_currency}
                </p>
              </div>
            </div>
            <div className="bonus-info">
              <h3>
                {global.locale.modal_pay_bonus}: {this.props.bonus}
              </h3>
              {this.props.bonus > 0 ? (
                <div className="form-group">
                  <label>{global.locale.modal_pay_pay_bonus}:</label>
                  <input
                    className="form-control"
                    maxlength="10"
                    placeholder=""
                    value={this.state.selectBonus}
                    onChange={(e) => this._changeBonus(e.target.value)}
                  />
                </div>
              ) : null}
            </div>
            <div className="promo-info">
              <div className="form-group form-group-info-btn-wrap">
                <label>{global.locale.modal_pay_promocode}:</label>
                <input
                  className="form-control"
                  maxlength="10"
                  placeholder=""
                  onBlur={(e) => {
                    this.setState({ promoCode: e.target.value });
                  
                  }}
                />
                <div className="form-group-info-btn">
                  <UiBtnGreen
                    btnText={global.locale.plan_card_btn_add}
                    btnsm
                    onClick={() => {
                      this.props.onPromo(this.state.promoCode);
                    }}
                  />
                </div>
                
              </div>
            </div>
            <div className="pay-sum">
              <span>{global.locale.modal_pay_to_pay}:</span>
              {this.props.sale == 0 ? (
                <h3>
                  {this.props.price - this.state.selectBonus}{" "}
                  {global.locale.modal_pay_currency}
                </h3>
              ) : (
                <p className="card-price-text">
                  {" "}
                  <span> {this.props.price - this.state.selectBonus} </span>
                  <strong>
                    {Math.round(
                      this.props.price -
                        this.state.selectBonus -
                        (this.props.price * this.props.sale) / 100
                    )}{" "}
                    {global.locale.modal_pay_currency}{" "}
                  </strong>
                </p>
              )}
            </div>
            <div>
              <small>{global.locale.modal_pay_NDS}</small>
            </div>
            <div className="payment-type">
              <div
                className={
                  "payment-item" + (this.state.activePay == 0 ? " active" : "")
                }
                onClick={() => this.setState({ activePay: 0 })}
              >
                <div className="pay-wrap">
                  <img
                    src={require("../../../assets/imgs/profile/credit-card.svg")}
                  />
                  <p>{global.locale.modal_pay_pay_card}</p>
                </div>
                <div className="check-wrap">
                  <img src={visaIcon} />
                  <img src={masterIcon} />
                  <div
                    className={
                      "check" + (this.state.activePay == 0 ? " active" : "")
                    }
                  ></div>
                </div>
              </div>
              {/*
                <div className="card-wrap">
                  <div className="card-row">
                    <input className="card-input" placeholder="0000 0000 0000 0000" />
                  </div>
                  <div className="card-row">
                    <input className="card-input card-input-50 mr-2" placeholder="MM/YY" />
                    <input className="card-input card-input-50" placeholder="CVC/CVV" />
                  </div>
                  <div className="card-row">
                    <input className="card-input" placeholder={global.locale.modal_pay_placeholder} />
                  </div>
                </div>
                */}

              {/*
              <div
                className={
                  "payment-item" + (this.state.activePay == 1 ? " active" : "")
                }
                onClick={() => this.setState({ activePay: 1 })}
              >
                <div className="pay-wrap">
                  <img
                    src={require("../../../assets/imgs/profile/wallet.svg")}
                  />
                  <p>{global.locale.modal_pay_cash_to_courier}</p>
                </div>
                <div className="check-wrap">
                  <div
                    className={
                      "check" + (this.state.activePay == 1 ? " active" : "")
                    }
                  ></div>
                </div>
              </div>
              <div
                className={
                  "payment-item" + (this.state.activePay == 2 ? " active" : "")
                }
                onClick={() => this.setState({ activePay: 2 })}
              >
                <div className="pay-wrap">
                  <img
                    src={require("../../../assets/imgs/profile/wallet.svg")}
                  />
                  <p>{global.locale.modal_pay_pay_card_to_courier}</p>
                </div>
                <div className="check-wrap">
                  <div
                    className={
                      "check" + (this.state.activePay == 2 ? " active" : "")
                    }
                  ></div>
                </div>
              </div>
              <div
                className={
                  "payment-item" + (this.state.activePay == 3 ? " active" : "")
                }
                onClick={() => this.setState({ activePay: 3 })}
              >
                <div className="pay-wrap">
                  <img
                    src={require("../../../assets/imgs/profile/wallet.svg")}
                  />
                  <p>{global.locale.modal_pay_bank_transfer}</p>
                </div>
                <div className="check-wrap">
                  <div
                    className={
                      "check" + (this.state.activePay == 3 ? " active" : "")
                    }
                  ></div>
                </div>
              </div>
                */}

              <div className="btn-payment-wrap">
                {this.state.activePay == 2 || this.state.activePay == 1 ? (
                  <UiBtnGreen
                    btnText={global.locale.modal_pay_btn_order}
                    onClick={() =>
                      this.props.callBack(
                        this.state.activePay,
                        this.state.selectBonus,
                        this.state.promoCode,
                        this.props.price
                      )
                    }
                  />
                ) : (
                  <UiBtnGreen
                    btnText={global.locale.modal_pay_btn_pay}
                    onClick={() =>
                      this.props.callBack(
                        this.state.activePay,
                        this.state.selectBonus,
                        this.state.promoCode,
                        this.props.price
                      )
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UiModalPay;
